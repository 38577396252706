import { connect } from 'react-redux';
import ListTable from 'src/components/listTable/ListTable';
import ListTableCell from 'src/components/listTable/ListTableCell';
import ListTableHeader from 'src/components/listTable/ListTableHeader';
import ListTableRow from 'src/components/listTable/ListTableRow';
import TagsOrCampaignsListItem from 'src/components/views/postTags/faceliftTags/TagsOrCampaignsListItem';
import TagsOrCampaignsTypeListItem from 'src/components/views/postTags/faceliftTags/TagsOrCampaignsTypeListItem';
import { selectAllCampaignIds } from 'src/reducers/slices/faceliftCampaigns';
import { selectAllTagIds } from 'src/reducers/slices/faceliftTags';
import PropTypes from 'prop-types';
import React from 'react';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import emptySearchImage from 'src/resources/illustrations/discover-no-results.png';
import _concat from 'lodash/concat';

const TagsAndCampaignsList = (props) => {
    const { tagIds, campaignIds } = props;

    const combinedIds = _concat(tagIds, campaignIds);

    const { length } = combinedIds;

    const columns = [
        {
            id: 'type', content: 'Type', isSortable: false, width: '2%'
        }, {
            id: 'name', content: 'Tag name', isSortable: false, width: '80%'
        }
    ];

    const tableHeader = <ListTableHeader columns={columns} />;

    return (
        <>
            {
                    length > 0
                        ? (
                            <ListTable header={tableHeader}>
                                {
                                    combinedIds.map((id) => (
                                        <ListTableRow>
                                            <ListTableCell>
                                                <TagsOrCampaignsTypeListItem id={id} />
                                            </ListTableCell>
                                            <ListTableCell>
                                                <TagsOrCampaignsListItem id={id} />
                                            </ListTableCell>
                                        </ListTableRow>
                                    ))
                                }
                            </ListTable>
                        )
                        : (
                            <EmptyInfoScreen
                              image={emptySearchImage}
                              title="No tags or campaigns found"
                            />
                        )
                }
        </>
    );
};

TagsAndCampaignsList.propTypes = {
    tagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    campaignIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

const mapStateToProps = (state) => ({
    tagIds: selectAllTagIds(state),
    campaignIds: selectAllCampaignIds(state),
});

export default connect(mapStateToProps)(TagsAndCampaignsList);
