import React from 'react';
import Body from 'src/components/Body';
import Footer from 'src/components/Footer';
import FaceliftTagsHeader from 'src/components/header/FaceliftTagsHeader';
import ScrollView from 'src/components/ScrollView';
import TagsAndCampaignsList from 'src/components/views/postTags/faceliftTags/TagsAndCampaignsList';
import withScrollPosition from 'src/components/withScrollPosition';
import * as customPropTypes from 'src/customPropTypes';

const ScrollViewWithScrollPosition = withScrollPosition(ScrollView, 'dataSources');

const FaceliftTags = (props) => {
    const { location } = props;
    return (
        <ScrollViewWithScrollPosition
          scrollPositionIdentifier="data-sources"
          locationKey={location.key}
          dataViewName="data-sources"
        >
            <FaceliftTagsHeader />
            <Body>
                <TagsAndCampaignsList />
            </Body>
            <Footer />
        </ScrollViewWithScrollPosition>
    );
};

FaceliftTags.propTypes = {
    location: customPropTypes.location.isRequired
};

export default FaceliftTags;
