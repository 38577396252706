import AuthenticatorContext from 'src/components/context/AuthenticatorContext';
import { PersistGate } from 'redux-persist/integration/react';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { createAuthenticator, registerDispatchToSessionExceptionHandler } from 'src/requestHandling/utils';
import { facebookSdkOptions, onboardingUrl } from 'src/config';
import {
    parseClientSetting,
    parseDashboardsDashboardReports,
    parseDashboardsDashboardMetrics,
    parseDataTypes,
    parseFavoriteMetrics,
    parseOwnershipFoldersWithVirtualFolders,
    parseGroups,
    parseLoggedInUser,
    parseMetrics,
    parseMetricsWithDashboardUsage,
    parsePackageStatus,
    parseAccountAuthenticationEntities,
    parseProfiles,
    parseSpaceLimits,
    parseTags,
    parseUserNotificationSettings,
    parseVisualizations,
    parseOwnershipDashboards,
    parseProfilesHealthStatus,
    parsePostTagsWithArchivedAndActiveTagIds,
    parsePostTagPostTagRules,
    parseAdAccounts, parseFaceliftTags, parseFaceliftCampaigns,
} from 'src/parsers';
import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import AppError from 'src/components/errors/AppError';
import { browserHistory } from 'react-router';
import configureStore from 'src/store/app.tool.configureStore';
import LoadingScreen from 'src/components/LoadingScreen';
import { prepareFacebookSDK } from 'src/actions/facebookSDK';
import { prepareGoogleTagManager } from 'src/actions/appEvents';
import { Provider } from 'react-redux';
import { createLogout } from 'src/utils/auth0';
import { getPersistConfig } from 'src/utils/redux-persist';
import { registerUserToSentry } from 'src/utils/sentry';
import { reportError } from 'src/utils/reportError';
import Root from 'src/components/Root';
import routes from 'src/routes.tool';
import { syncHistoryWithStore } from 'react-router-redux';
import { userLoggedIn } from 'src/actions/loggedInUser';
import { generateInitialFilterSelectors } from 'src/utils/filterSelectors';
import { useAuth0 } from '@auth0/auth0-react';

const getInitialFilterSelectors = () => ({
    filterSelectors: generateInitialFilterSelectors()
});

const AppProviderTool = () => {
    const [history, setHistory] = useState(null);
    const [store, setStore] = useState(null);
    const [persistor, setPersistor] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [bootstrapped, setBootstrapped] = useState(false);
    const {
        getAccessTokenSilently,
        logout
    } = useAuth0();

    const [authenticator, setAuthenticator] = useState(createAuthenticator({ getAccessTokenSilently, logout }, createLogout, 'Bearer'));

    useEffect(() => {
        const bootstrapAction = async () => {
            // user info im token.
            const accessToken = await getAccessTokenSilently();
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const serverJob = createServerRequest({}, 'json', headers);
            serverJob('/client-public/loginForMalibu')
                .then((response) => {
                    const { jsonData: data } = response;

                    // This will no longer be from the session, but from the token
                    const userId = data.userSpace.user.id;
                    const spaceId = data.userSpace.space.id;
                    const { session } = data;
                    const initialFilterSelectors = getInitialFilterSelectors();
                    const spaceHeaderFactory = () => {
                        const header = {
                            'X-SpaceId': spaceId,
                        };
                        if (session.isAgent) {
                            header['X-OnBehalf'] = userId;
                        }
                        return header;
                    };
                    // update authenticator
                    const spaceContextAuthenticator = createAuthenticator({ getAccessTokenSilently, logout }, createLogout, 'Bearer', spaceHeaderFactory);
                    const {
                        store: tempStore,
                        persistor: tempPersistor
                    } = configureStore(initialFilterSelectors, { authenticator: spaceContextAuthenticator }, getPersistConfig(userId, spaceId));
                    registerDispatchToSessionExceptionHandler(tempStore.dispatch);
                    try {
                        const loggedInUser = parseLoggedInUser(data, session.isAgent);
                        const clientSettings = parseClientSetting(data.client);
                        registerUserToSentry(loggedInUser.userId, clientSettings.clientId);
                        tempStore.dispatch(prepareGoogleTagManager(process.env.NODE_ENV !== 'production' || session.isAgent));
                        tempStore.dispatch(prepareFacebookSDK(facebookSdkOptions));
                        tempStore.dispatch(userLoggedIn(
                            parseOwnershipDashboards(data.dashboards),
                            parseProfiles(data.accountDomainEntities.profiles),
                            parseAdAccounts(data.accountDomainEntities.adAccounts),
                            parseAccountAuthenticationEntities(data.accountDomainEntities),
                            parseOwnershipFoldersWithVirtualFolders(data),
                            parseGroups(data.accountDomainEntities.groups),
                            parseMetrics(data),
                            parseMetricsWithDashboardUsage(data),
                            loggedInUser,
                            parseDashboardsDashboardMetrics(data.dashboards.dashboard),
                            parseDashboardsDashboardReports(data.dashboards.dashboard),
                            parseTags(data),
                            parseDataTypes(data),
                            parseVisualizations(data),
                            parseSpaceLimits(data),
                            parsePackageStatus(data),
                            clientSettings,
                            null,
                            parseProfilesHealthStatus(data.profileOverallHealth),
                            parseFavoriteMetrics(data),
                            parseUserNotificationSettings(data.userSpace.user.userNotificationSettings),
                            parsePostTagsWithArchivedAndActiveTagIds(data.postTags),
                            parsePostTagPostTagRules(data.postTags),
                            parseFaceliftCampaigns(data.faceliftCampaigns),
                            parseFaceliftTags(data.faceliftTags)
                        ));
                        const enhancedHistory = syncHistoryWithStore(browserHistory, tempStore);
                        setIsLoading(false);
                        setHistory(enhancedHistory);
                        setStore(tempStore);
                        setPersistor(tempPersistor);
                        setBootstrapped(true);
                        setAuthenticator(spaceContextAuthenticator);
                    } catch (applicationError) {
                        reportError(applicationError);
                        setIsLoading(false);
                        setError(applicationError);
                    }
                })
                .catch((serverError) => {
                    if (_get(serverError, 'errorType') === 'clientRequiresOnboarding') {
                        window.location = onboardingUrl;
                        return;
                    }
                    if (_get(serverError, 'errorType') === 'userRequiresOnboarding') {
                        window.location = onboardingUrl;
                        return;
                    }
                    setIsLoading(false);
                    setError(serverError);
                });
        };

        bootstrapAction();
    }, []);

    if (error) {
        return (
            <AuthenticatorContext.Provider value={authenticator}>
                <AppError error={error} />
            </AuthenticatorContext.Provider>
        );
    }
    if (isLoading) {
        return <LoadingScreen />;
    }
    if (bootstrapped) {
        return (
            <AuthenticatorContext.Provider value={authenticator}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        { (persistorBootstrapped) => {
                            if (persistorBootstrapped) {
                                return <Root history={history} routes={routes} />;
                            }
                            return <LoadingScreen />;
                        }}
                    </PersistGate>
                </Provider>
            </AuthenticatorContext.Provider>
        );
    }
    return null;
};

export default AppProviderTool;
