import React from 'react';
import { connect } from 'react-redux';
import IcomoonIcon from 'src/components/IcomoonIcon';
import * as customPropTypes from 'src/customPropTypes';
import { makeSelectActiveCombinedPostTagsById } from 'src/selectors/combindedPostTags';

const TagsOrCampaignsTypeListItem = ({ item }) => <div><IcomoonIcon icon={item.type === 'campaign' ? 'ads' : item.type} /></div>;

TagsOrCampaignsTypeListItem.propTypes = {
    item: customPropTypes.faceliftTagOrCampaign.isRequired
};

const makeMapStateToProps = () => {
    const selectActiveCombinedPostTagsById = makeSelectActiveCombinedPostTagsById();
    return (state, ownProps) => {
        const item = selectActiveCombinedPostTagsById(state, ownProps.id);
        if (!item) {
            throw new Error('Invalid id');
        }
        return { item };
    };
};

export default connect(makeMapStateToProps)(TagsOrCampaignsTypeListItem);
