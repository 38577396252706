import React from 'react';
import ContentBlock from 'src/components/views/ContentBlock';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/views/dataSources/dataSourceInfo.scss';
import DetailText from 'src/components/views/DetailText';
import DataSourceHints from 'src/components/DataSourceHints';
import DataSourceInfoListItem from 'src/components/views/dataSources/DataSourceInfoListItem';
import withPopover from 'src/components/withPopover';
import {
    getLabelAndIconByIsPrivateStatistic, getLabelAndIconByIsTimeSeries, getOptionalFiltersInfo, getDeprecationDateString, isDeprecateDatePassedFromNow
} from 'src/utils/dataSources';
import TimeSeriesItemLevelDataDescriptionPopover from 'src/components/popovers/TimeSeriesItemLevelDataDescriptionPopover';
import PrivatePublicLevelDataDescriptionPopover from 'src/components/popovers/PrivatePublicLevelDataDescriptionPopover';
import ApiAccessDescriptionPopover from 'src/components/popovers/ApiAccessDescriptionPopover';
import PostTextFilterDescriptionPopover from 'src/components/popovers/PostTextFilterDescriptionPopover';
import PostTagFilterDescriptionPopover from 'src/components/popovers/PostTagFilterDescriptionPopover';
import withTooltip from 'src/components/withToolTip';

const DataSourceInfoListItemWithTooltipWithPopover = withTooltip(withPopover(DataSourceInfoListItem));
const DataSourceInfoListItemWithPopover = withPopover(DataSourceInfoListItem);

const renderItems = (label, icon, tooltip, overlay, disabled) => {
    const sharedParams = {
        label,
        disabled,
        overlay,
        icon,
        placement: 'bottom-start'
    };
    return disabled
        ? <DataSourceInfoListItemWithTooltipWithPopover {...sharedParams} tooltip={tooltip} />
        : <DataSourceInfoListItemWithPopover {...sharedParams} />;
};

const DataSourceInfo = (props) => {
    const {
        isPrivateStatistic,
        isTimeSeries,
        description,
        hints,
        isApiAccessSupported,
        isDeprecated,
        deprecationDate,
        optionalFilters,
        postTextFilterAttributes
    } = props;

    const privateOrPublicData = getLabelAndIconByIsPrivateStatistic(isPrivateStatistic);
    const itemOrTimeSeriesData = getLabelAndIconByIsTimeSeries(isTimeSeries);
    const { hasPostTextFilter, hasPostTagFilter } = getOptionalFiltersInfo(optionalFilters);
    return (
        <div className={styles.wrapper}>
            <div className={styles.leftSection}>
                <div className={styles.info}>
                    <div className={styles.infoItem}>
                        {renderItems(privateOrPublicData.label, privateOrPublicData.icon, '', <PrivatePublicLevelDataDescriptionPopover isPrivateStatistic={isPrivateStatistic} />, false)}
                    </div>
                    <div className={styles.infoItem}>
                        {renderItems(itemOrTimeSeriesData.label, itemOrTimeSeriesData.icon, '', <TimeSeriesItemLevelDataDescriptionPopover isTimeSeries={isTimeSeries} />, false)}
                    </div>
                    <div className={styles.infoItem}>
                        {renderItems('Keywords', 'search', 'This data source does not support the keywords filter.', <PostTextFilterDescriptionPopover filterAttributes={postTextFilterAttributes} />, !hasPostTextFilter)}
                    </div>
                    <div className={styles.infoItem}>
                        {renderItems('Post tags', 'tag', 'This data source does not support the post tags filter', <PostTagFilterDescriptionPopover />, !hasPostTagFilter)}
                    </div>
                    <div className={styles.infoItemWithoutRightBorder}>
                        {renderItems('API access', 'data', 'This data source does not support API access.', <ApiAccessDescriptionPopover />, !isApiAccessSupported)}
                    </div>
                </div>
                <ContentBlock className={styles.descriptionSection}>
                    <DetailText label="Description">
                        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
                        {
                            isDeprecated && deprecationDate !== ''
                            && (
                                <div className={styles.deprecationInfo}>
                                    This data source {isDeprecateDatePassedFromNow(deprecationDate) ? 'was deprecated' : 'deprecates'} on <b>{getDeprecationDateString(deprecationDate)}</b>. Learn more about
                                    recent changes and upcoming deprecations of data sources <a href="https://help.facelift-cloud.com/en/articles/8707482-analytics-data-source-change-log" target="_blank" rel="noopener noreferrer">here</a>.
                                </div>
                            )
                        }
                    </DetailText>
                </ContentBlock>
            </div>
            <div className={styles.rightSection}>
                <ContentBlock>
                    <DetailText label="Hints">
                        {
                            hints.length > 0
                                ? <DataSourceHints hints={hints} paddingSize="big" />
                                : <div className={styles.emptyHints}>Hints not available</div>
                        }
                    </DetailText>
                </ContentBlock>
            </div>
        </div>
    );
};

DataSourceInfo.propTypes = {
    isPrivateStatistic: PropTypes.bool.isRequired,
    isTimeSeries: PropTypes.bool.isRequired,
    isApiAccessSupported: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    hints: PropTypes.arrayOf(PropTypes.string).isRequired,
    isDeprecated: PropTypes.bool.isRequired,
    deprecationDate: PropTypes.string,
    optionalFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
    postTextFilterAttributes: PropTypes.arrayOf(PropTypes.string)
};

DataSourceInfo.defaultProps = {
    deprecationDate: '',
    postTextFilterAttributes: []
};

export default DataSourceInfo;
