import { makeSelectAdminSettingsState } from 'src/selectors/adminSettings';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Column from 'src/components/header/layout/Column';
import { connect } from 'react-redux';
import Header from 'src/components/header/layout/Header';
import { modalShowCreateUser } from 'src/actions/overlays';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import PropTypes from 'prop-types';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';
import * as customPropTypes from 'src/customPropTypes';
import { selectIsSSO } from 'src/selectors/loggedInUser';

const UsersHeader = (props) => {
    const { modalShowCreateUserAction, loadingState, isSSO } = props;
    const { isPending, error } = loadingState;

    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <PageHeadline>User management</PageHeadline>
                </Column>
                <Column>
                    {
                        !isSSO
                        && (
                            <ButtonGroup>
                                <Button
                                  onClick={modalShowCreateUserAction}
                                  label="Invite user"
                                  action
                                  icon="plus"
                                  disabled={isPending || !!error}
                                />
                            </ButtonGroup>
                        )
                    }
                    {
                        isSSO
                        && (
                            <div>User accounts are managed and provisioned via SSO</div>
                        )
                    }
                </Column>
            </TopRow>
        </Header>
    );
};

UsersHeader.propTypes = {
    modalShowCreateUserAction: PropTypes.func.isRequired,
    loadingState: customPropTypes.asyncDataLoadingState.isRequired,
    isSSO: PropTypes.bool
};

UsersHeader.defaultProps = {
    isSSO: false
};

const makeMapStateToProps = () => {
    const selectAdminSettingsState = makeSelectAdminSettingsState();
    return (state) => ({
        loadingState: selectAdminSettingsState(state),
        isSSO: selectIsSSO(state)
    });
};

export default connect(makeMapStateToProps, { modalShowCreateUserAction: modalShowCreateUser })(UsersHeader);
