import React from 'react';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import { makeSelectActiveCombinedPostTagsById } from 'src/selectors/combindedPostTags';

const TagsOrCampaignsListItem = ({ item }) => <div>{item.name}</div>;

TagsOrCampaignsListItem.propTypes = {
    item: customPropTypes.faceliftTagOrCampaign.isRequired
};

const makeMapStateToProps = () => {
    const selectActiveCombinedPostTagsById = makeSelectActiveCombinedPostTagsById();
    return (state, ownProps) => {
        const item = selectActiveCombinedPostTagsById(state, ownProps.id);
        if (!item) {
            throw new Error('Invalid id');
        }
        return { item };
    };
};

export default connect(makeMapStateToProps)(TagsOrCampaignsListItem);
