import AllDashboards from 'src/components/views/dashboards/AllDashboards';
import FolderDashboards from 'src/components/views/dashboards/FolderDashboards';
import SharedDashboards from 'src/components/views/dashboards/SharedDashboards';
import UserDashboards from 'src/components/views/dashboards/UserDashboards';
import FaceliftTags from 'src/components/views/postTags/faceliftTags/FaceliftTags';
import { tagsFacelift } from 'src/routePaths';
import { ADMIN_ONLY, NON_READ_ONLY_USER } from 'src/selectors/privilegeCheckType';
import {
    DiscoverResultsAllMetrics,
    DiscoverResultsCustomMetrics,
    DiscoverResultsPublicMetrics
} from 'src/components/views/discover/DiscoverResultsMetrics';
import { IndexRoute, Redirect, Route } from 'react-router';
import Account from 'src/components/views/settings/account/Account';
import Analysis from 'src/components/views/analysis/Analysis';
import AnalysisContent from 'src/components/views/analysis/AnalysisContent';
import AppTool from 'src/components/AppTool';
import Authentications from 'src/components/views/authentications/Authentications';
import Billing from 'src/components/views/settings/billing/Billing';
import Security from 'src/components/views/settings/Security';
import CustomMetrics from 'src/components/views/custom-metrics/CustomMetrics';
import Dashboard from 'src/components/views/dashboards/Dashboard';
import DashboardDetail from 'src/components/views/discover/dashboardDetail/DashboardDetail';
import DataPushTasks from 'src/components/views/integrations/dataPushTasks/DataPushTasks';
import DataPushTaskDetail from 'src/components/views/integrations/dataPushTasks/dataPushTaskDetail/DataPushTaskDetail';
import DiscoverResultsDashboards from 'src/components/views/discover/DiscoverResultsDashboards';
import DiscoverStartCustomMetrics from 'src/components/views/discover/startPages/DiscoverStartCustomMetrics';
import DiscoverStartDashboards from 'src/components/views/discover/startPages/DiscoverStartDashboards';
import DiscoverStartMetrics from 'src/components/views/discover/startPages/DiscoverStartMetrics';
import DiscoverStartPublicMetrics from 'src/components/views/discover/startPages/DiscoverStartPublicMetrics';
import MaximizedDashboardMetric from 'src/components/views/dashboards/MaximizedDashboardMetric';
import MetricBuilder from 'src/components/views/custom-metrics/MetricBuilder';
import MetricDetail from 'src/components/views/discover/metricDetail/MetricDetail';
import MetricPreview from 'src/components/views/discover/MetricPreview';
import AdminOverview from 'src/components/views/settings/AdminOverview';
import PageNotFound from 'src/components/views/PageNotFound';
import Profiles from 'src/components/views/profiles/Profiles';
import React from 'react';
import SampleDashboard from 'src/components/sampleDashboard/SampleDashboard';
import SharedDashboardContext from 'src/components/views/dashboards/SharedDashboardContext';
import SpaceManagement from 'src/components/views/settings/space/SpaceManagement';
import UserNotifications from 'src/components/views/userNotifications/UserNotifications';
import UserSettings from 'src/components/views/settings/users/UserSettings';
import withBlockedOrExpiredCheck from 'src/components/withBlockedOrExpiredCheck';
import withEnforcedSelectorParameters from 'src/components/views/withEnforcedSelectorParameters';
import withPrivilegeCheck from 'src/components/withPrivilegeCheck';
import AnalysisView from 'src/components/views/AnalysisView';
import AccountsView from 'src/components/views/AccountsView';
import AccountSettingsView from 'src/components/views/AccountSettingsView';
import AdminSettingsView from 'src/components/views/AdminSettingsView';
import NotificationsView from 'src/components/views/NotificationsView';
import DataSourcesView from 'src/components/views/DataSourcesView';
import IntegrationsView from 'src/components/views/IntegrationsView';
import Preferences from 'src/components/views/settings/Preferences';
import DataSources from 'src/components/views/dataSources/DataSources';
import DataSourceDetailWithDataSources from 'src/components/views/dataSources/DataSourceDetailWithDataSources';
import DataPushTaskLogDetailWithDataPushTask from 'src/components/views/integrations/DataPushTaskLogDetailWithDataPushTask';
import PostTagsView from 'src/components/views/PostTagsView';
import PostTagManager from 'src/components/views/postTags/postTagManager/PostTagManager';
import Posts from 'src/components/views/postTags/postTagging/Posts';
import withDashboardEnforcedSelectorParameters from 'src/components/views/dashboards/withDashboardEnforcedSelectorParameters';
import {
    QUICK_ANALYSIS, METRIC_BUILDER, DISCOVER_PREVIEW, POST_TAGGING
} from 'src/utils/filterSelectors';
import MaximizedEditPostTag from 'src/components/views/postTags/postTagManager/MaximizedEditPostTag';
import API from 'src/components/views/integrations/api/API';
import DataPushTaskMissingAdminRoleError from 'src/components/errors/DataPushTaskMissingAdminRoleError';
import APIMissingAdminRoleErrorView from 'src/components/errors/APIMissingAdminRoleErrorView';
import ReportsOverviewManager from 'src/components/views/reports/ReportsOverviewManager';
import AdAccounts from 'src/components/views/adAccounts/AdAccounts';

const MetricBuilderWithSelectorParameters = withEnforcedSelectorParameters(MetricBuilder, METRIC_BUILDER);
const AppToolWithBlockedOrExpiredAccountCheck = withBlockedOrExpiredCheck(AppTool);
const SharedDashboardContextWithEnforcedSelectorParams = withDashboardEnforcedSelectorParameters(SharedDashboardContext);
const MetricPreviewWithEnforcedSelectorParameters = withEnforcedSelectorParameters(MetricPreview, DISCOVER_PREVIEW);
const AnalysisContentWithEnforcedSelectorParameters = withEnforcedSelectorParameters(AnalysisContent, QUICK_ANALYSIS);
const AdminSettingsViewWithAdminOnlyCheck = withPrivilegeCheck(AdminSettingsView, ADMIN_ONLY);
const PostsWithPrivilegeCheckWithEnforcedSelectorParameters = withPrivilegeCheck(withEnforcedSelectorParameters(Posts, POST_TAGGING), NON_READ_ONLY_USER);
const PostTagManagerWithPrivilegeCheck = withPrivilegeCheck(PostTagManager, NON_READ_ONLY_USER);
const MaximizedEditPostTagWithPrivilegeCheck = withPrivilegeCheck(MaximizedEditPostTag, NON_READ_ONLY_USER);

const DataPushTasksWithAdminOnlyCheck = withPrivilegeCheck(DataPushTasks, ADMIN_ONLY, <DataPushTaskMissingAdminRoleError />);
const DataPushTaskDetailWithAdminOnlyCheck = withPrivilegeCheck(DataPushTaskDetail, ADMIN_ONLY, <DataPushTaskMissingAdminRoleError />);
const DataPushTaskLogDetailWithDataPushTaskWidthAdminOnly = withPrivilegeCheck(DataPushTaskLogDetailWithDataPushTask, ADMIN_ONLY, <DataPushTaskMissingAdminRoleError />);
const APIWidthAdminOnly = withPrivilegeCheck(API, ADMIN_ONLY, <APIMissingAdminRoleErrorView />);

export default (
    <div>
        <Redirect from="/" to="analysis" />
        <Route path="/" component={AppToolWithBlockedOrExpiredAccountCheck}>
            <Route components={{ content: AnalysisView }}>
                <Redirect from="discover" to="/discover/dashboards" />
                <Route path="discover">
                    <Route path="metrics" components={{ content: DiscoverStartMetrics }} />
                    <Route path="metrics/search" components={{ content: DiscoverResultsAllMetrics }} />
                    <Route path="public-metrics" components={{ content: DiscoverStartPublicMetrics }} />
                    <Route path="public-metrics/search" components={{ content: DiscoverResultsPublicMetrics }} />
                    <Route path="custom-metrics" components={{ content: DiscoverStartCustomMetrics }} />
                    <Route path="custom-metrics/search" components={{ content: DiscoverResultsCustomMetrics }} />
                    <Route path="metric/:metricId" components={{ content: MetricDetail }} />
                    <Route path="metric/:metricId/preview" components={{ content: MetricPreviewWithEnforcedSelectorParameters }} />
                    <Route path="dashboards" components={{ content: DiscoverStartDashboards }} />
                    <Route path="dashboards/search" components={{ content: DiscoverResultsDashboards }} />
                    <Route path="dashboard/:dashboardId" components={{ content: DashboardDetail }} />
                </Route>
                <Route path="dashboards" components={{ content: AllDashboards }} />
                <Redirect from="folder/SUPER_ROOT" to="/dashboards" />
                <Route path="user-dashboards" components={{ content: UserDashboards }} />
                <Route path="shared-dashboards" components={{ content: SharedDashboards }} />

                <Redirect from="folder/shared" to="/shared-dashboards" />
                <Redirect from="folder/user" to="/user-dashboards" />

                <Route path="folder/:folderId" components={{ content: FolderDashboards }} />
                <Route path="dashboard/:dashboardId" components={{ content: SharedDashboardContextWithEnforcedSelectorParams }}>
                    <IndexRoute components={{ content: Dashboard }} />
                    <Route path="metric/:dashboardMetricId" components={{ content: MaximizedDashboardMetric }} />
                </Route>
                <Route path="reports" components={{ content: ReportsOverviewManager }} />
                <Route path="analysis" components={{ content: Analysis }}>
                    <Route path=":metricId" components={{ analysisContent: AnalysisContentWithEnforcedSelectorParameters }} />
                </Route>
                <Route path="custom-metrics" components={{ content: CustomMetrics }} />
                <Route path="metric-builder" components={{ content: MetricBuilderWithSelectorParameters }} />
                <Route path="metric-builder/:metricId" components={{ content: MetricBuilderWithSelectorParameters }} />
            </Route>
            <Route path="sample-dashboard/:dashboardId" components={{ content: SampleDashboard }} />
            <Route components={{ content: DataSourcesView }}>
                <Route path="data-sources" components={{ content: DataSources }} />
                <Route path="data-sources/:dataSourceId" components={{ content: DataSourceDetailWithDataSources }} />
            </Route>
            <Route components={{ content: IntegrationsView }}>
                <Route path="data-push-tasks" components={{ content: DataPushTasksWithAdminOnlyCheck }} />
                <Route path="data-push-tasks/:dataPushTaskId" components={{ content: DataPushTaskDetailWithAdminOnlyCheck }} />
                <Route path="data-push-tasks/:dataPushTaskId/log/:logId" components={{ content: DataPushTaskLogDetailWithDataPushTaskWidthAdminOnly }} />
                <Route path="access-api" components={{ content: APIWidthAdminOnly }} />
            </Route>
            <Route components={{ content: AccountsView }}>
                <Route path="profiles" components={{ content: Profiles }} />
                <Route path="ad-accounts" components={{ content: AdAccounts }} />
                <Route path="authentications" components={{ content: Authentications }} />
            </Route>
            <Route components={{ content: PostTagsView }}>
                <Route path="posts" components={{ content: PostsWithPrivilegeCheckWithEnforcedSelectorParameters }} />
                <Route path="post-tag-manager" components={{ content: PostTagManagerWithPrivilegeCheck }} />
                <Route path="post-tag-manager/:postTagId" components={{ content: MaximizedEditPostTagWithPrivilegeCheck }} />
                <Route path={tagsFacelift} components={{ content: FaceliftTags }} />
            </Route>
            <Route components={{ content: AdminSettingsViewWithAdminOnlyCheck }}>
                <Route path="users" components={{ content: UserSettings }} />
                <Route path="spaces" components={{ content: SpaceManagement }} />
            </Route>
            <Redirect from="settings" to="/settings/account" />
            <Route path="settings">
                <Route components={{ content: AccountSettingsView }}>
                    <Route path="account" components={{ content: Account }} />
                    <Route path="preferences" components={{ content: Preferences }} />
                    <Route path="security" components={{ content: Security }} />
                </Route>
                <Route components={{ content: AdminSettingsViewWithAdminOnlyCheck }}>
                    <Route path="plan" components={{ content: AdminOverview }} />
                    <Route path="billing" components={{ content: Billing }} />
                </Route>
            </Route>
            <Route components={{ content: NotificationsView }}>
                <Route path="user-notifications" components={{ content: UserNotifications }} />
            </Route>
            <Route path="*" components={{ content: PageNotFound }} />
        </Route>
    </div>
);
