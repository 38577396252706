import { authTransactionRequirements } from 'src/reducers/entities/authTransactionRequirements';
import { authTransactions } from 'src/reducers/entities/authTransactions';
import { authUsers } from 'src/reducers/entities/authUsers';
import { authUsersAuthProfileUseCases } from 'src/reducers/entities/authUsersAuthProfileUseCases';
import { combineReducers } from 'redux';
import { dashboardMetrics } from 'src/reducers/entities/dashboardMetrics';
import { dashboards } from 'src/reducers/entities/dashboards';
import { dataPushTaskLogs } from 'src/reducers/entities/dataPushTaskLogs';
import { dataPushTasks } from 'src/reducers/entities/dataPushTasks';
import { dataPushTaskDestinations } from 'src/reducers/entities/dataPushTaskDestination';
import { dataSources } from 'src/reducers/entities/dataSources';
import { dataTypes } from 'src/reducers/entities/dataTypes';
import { folders } from 'src/reducers/entities/folders';
import { groups } from 'src/reducers/entities/groups';
import { invoices } from 'src/reducers/entities/invoices';
import { metrics } from 'src/reducers/entities/metrics';
import { metricSearchResults } from 'src/reducers/entities/metricSearchResults';
import { platformPermissions } from 'src/reducers/entities/platformPermissions';
import { profiles } from 'src/reducers/entities/profiles';
import { accountUseCases } from 'src/reducers/entities/accountUseCases';
import { profileUseCasesAuthUsers } from 'src/reducers/entities/profileUseCasesAuthUsers';
import { spaces } from 'src/reducers/entities/spaces';
import { tags } from 'src/reducers/entities/tags';
import { useCases } from 'src/reducers/entities/useCases';
import { users } from 'src/reducers/entities/users';
import { visualizations } from 'src/reducers/entities/visualizations';
import { profileEvents } from 'src/reducers/entities/profileEvents';
import { postTags } from 'src/reducers/entities/postTags';
import { postTagRules } from 'src/reducers/entities/postTagRules';
import { userInvitations } from 'src/reducers/entities/userInvitations';
import { dashboardShareLinks } from 'src/reducers/entities/dashboardShareLinks';
import { dashboardReports } from 'src/reducers/entities/dashboardReports';
import { adAccounts } from 'src/reducers/entities/adAccounts';
import { adAccountUseCasesAuthUsers } from 'src/reducers/entities/adAccountUseCasesAuthUsers';
import { authUsersAuthAdAccountUseCases } from 'src/reducers/entities/authUsersAuthAdAccountUseCases';
import { adAccountCampaigns } from 'src/reducers/entities/adAccountCampaigns';
import faceliftTags from 'src/reducers/slices/faceliftTags';
import faceliftCampaigns from 'src/reducers/slices/faceliftCampaigns';

export const entities = combineReducers({
    accountUseCases,
    adAccounts,
    adAccountUseCasesAuthUsers,
    authUsersAuthAdAccountUseCases,
    authUsers,
    authTransactions,
    authTransactionRequirements,
    authUsersAuthProfileUseCases,
    dashboardMetrics,
    dashboardShareLinks,
    dashboards,
    dashboardReports,
    dataPushTaskLogs,
    dataPushTasks,
    dataPushTaskDestinations,
    dataSources,
    dataTypes,
    folders,
    groups,
    invoices,
    metrics,
    metricSearchResults,
    platformPermissions,
    profiles,
    profileEvents,
    profileUseCasesAuthUsers,
    spaces,
    tags,
    useCases,
    users,
    visualizations,
    postTags,
    postTagRules,
    userInvitations,
    adAccountCampaigns,
    faceliftTags,
    faceliftCampaigns
});
